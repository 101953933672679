<template>
  <div>
    <div class="toolbar py-5 py-lg-15">
      <div class="container-xxl d-flex flex-stack flex-wrap">
        <div class="page-title d-flex flex-column me-3">
          <h1 class="text-white">{{ address }}</h1>
          <px-new-breadcrumbs :breadcrumbs="breadcrumbs" />
        </div>

        <div class="align-self-start">
          <router-link
            :to="{ name: 'single-project-archive', params: { id: projectId } }"
            class="btn btn-sm btn-custom btn-active-white btn-flex btn-color-white btn-active-color-primary"
          >
            <i class="bi bi-archive me-1" />
            Archive
          </router-link>
        </div>
      </div>
    </div>

    <div class="container-xxl pb-5 pb-xxl-8">
      <div class="content flex-row-fluid">
        <div class="row mb-5 mb-xxl-8">
          <div class="col-md-8 mb-4 mb-md-0">
            <div class="card h-100">
              <div class="card-body pt-9 pb-0 d-flex flex-column">
                <div class="d-flex flex-wrap flex-sm-nowrap mb-7">
                  <div class="me-7">
                    <div class="w-200px">
                      <div class="position-relative rounded ratio ratio-1x1 overflow-hidden">
                        <googlemaps-map
                          class="position-absolute"
                          :center.sync="center"
                          :zoom="16"
                          :options="{
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            overviewMapControl: false,
                            rotateControl: false,
                            draggable: false,
                            scrollwheel: false,
                            disableDoubleClickZoom: true,
                            controlSize: 20,
                          }"
                        >
                          <googlemaps-marker v-if="!isMapLoading" :position.sync="center" />
                        </googlemaps-map>
                        <px-loader v-if="isMapLoading" :is-full-window="false" bg="blue" />
                        <div
                          v-if="isNoMapAvailable"
                          class="position-absolute top-0 bottom-0 start-0 end-0 z-index-2 d-flex align-items-center justify-content-center bg-light-info rounded border border-info p-4"
                        >
                          <span class="fs-8 text-info">No Map Available</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1 d-flex flex-column">
                    <div
                      v-if="singleProject && singleProject.attributes"
                      class="d-md-flex justify-content-between align-items-start flex-wrap mb-6"
                    >
                      <div class="position-relative pe-10 mb-6 mb-xl-0">
                        <px-list-has-icon-item
                          v-for="(aboutItem, index) in about"
                          :key="aboutItem.id"
                          :extended-class="[{ 'mt-2': index !== 0 }]"
                          :title="aboutItem.attributes.title"
                          :svg-icon="aboutItem.attributes.icon"
                        />
                      </div>

                      <div class="d-flex align-items-center ms-2">
                        <span
                          v-if="singleProject && singleProject.attributes"
                          class="badge badge-light-primary fw-medium text-uppercase text-ls fs-10 px-3 py-2"
                        >
                          {{ singleProject.attributes.status || 'Lead' }}
                        </span>
                        <px-dropdown
                          class="ms-4"
                          :items="[
                            { title: 'Edit project', action: openProjectModal },
                            { title: 'Share project', action: openShareModal },
                          ]"
                        />
                      </div>
                    </div>

                    <div class="d-xl-flex justify-content-between align-items-start flex-wrap mt-auto">
                      <small class="d-block text-muted mt-auto mb-6 mb-xl-0">
                        Created:
                        <strong v-if="activityDates.created.length">
                          {{ activityDates.created | moment('DD.MM.YYYY') }}
                        </strong>
                        | Last Updated:
                        <strong v-if="activityDates.updated.length">
                          {{ activityDates.updated | moment('DD.MM.YYYY') }}
                        </strong>
                      </small>
                    </div>
                  </div>
                </div>

                <div class="border-top mt-auto">
                  <ul
                    v-if="navMenu"
                    class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fw-medium"
                  >
                    <li v-for="item in navMenu" :key="item.id" class="nav-item">
                      <router-link
                        :class="[
                          'nav-link text-active-primary ms-0 me-lg-8 py-4',
                          { active: item.attributes.pathName === currentPathName },
                        ]"
                        :to="{ name: item.attributes.pathName }"
                      >
                        {{ item.attributes.title }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card h-100">
              <div class="card-body pt-9">
                <div v-if="singleProject && singleProject.attributes" class="d-flex mb-4">
                  <div class="me-8">
                    <div class="fs-3 fw-bold">
                      {{ $n(singleProject.attributes.balance.sub_total, 'currency', 'en-CA') }}
                    </div>
                    <div class="text-uppercase text-ls text-muted fs-9 mb-4">Budget</div>
                  </div>
                  <div class="text-muted me-4">
                    <div class="fs-3 fw-bold">
                      {{ $n(singleProject.attributes.balance.outstanding, 'currency', 'en-CA') }}
                    </div>
                    <div class="text-uppercase text-ls text-muted fs-9 mb-4">Outstanding with HST</div>
                  </div>
                </div>

                <px-progress-with-text
                  v-for="(projectBreakdownItem, index) in projectBreakdown"
                  :key="projectBreakdownItem.id"
                  :extended-class="[{ 'mt-2': index !== 0 }]"
                  :progress-bg-class="projectBreakdownItem.attributes.bg"
                  :value="$n(projectBreakdownItem.attributes.sum, 'currency', 'en-CA')"
                  :title="projectBreakdownItem.attributes.title"
                  :side-text="projectBreakdownItem.attributes.qty"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="position-relative min-h-200px">
          <px-loader v-if="!singleProject" :is-full-window="false" bg="blue" />
          <router-view v-if="singleProject" :item="singleProject" />
        </div>
      </div>
    </div>

    <!-- edit project begin -->
    <px-new-modal :is-visible="isProjectModalOpened" :close-modal="closeProjectModal">
      <template #title>
        <h3>Edit Project</h3>
      </template>
      <template #content>
        <pxm-project :item="singleProject" @onClose="closeProjectModal" @on-update="onProjectUpdate" />
      </template>
    </px-new-modal>
    <!-- edit project end -->

    <!-- share begin -->
    <px-new-modal modal-size="mw-650px" :is-visible="isShareModalOpened" :close-modal="closeShareModal">
      <template #title>
        <h3>Share</h3>
      </template>
      <template #content>
        <pxm-share-compact
          :item-id="singleProject.id"
          :code="singleProject.attributes.code"
          :secret="singleProject.attributes.secret"
          @onClose="closeShareModal"
        />
      </template>
    </px-new-modal>
    <!-- share end -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import PxNewBreadcrumbs from '@/components/elements/PxNewBreadcrumbs';

import PxmProject from '@/modals/PxmProject';
import PxmShareCompact from '@/modals/PxmShareCompact';

export default {
  components: { PxNewBreadcrumbs, PxmProject, PxmShareCompact },
  computed: {
    ...mapGetters({
      singleProject: 'singleProject/project',
    }),
    projectId() {
      return this.$route.params.id;
    },
    currentPathName() {
      return this.$route.name;
    },
    isArchived() {
      return this.singleProject?.attributes?.archive;
    },
    address() {
      return this.singleProject?.attributes?.address;
    },
    breadcrumbs() {
      const breadcrumbs = [
        {
          id: '1001',
          title: 'Home',
          to: 'dashboard',
        },
        {
          id: '1002',
          title: 'Projects',
          to: 'projects',
        },
      ];

      if (this.isArchived) {
        breadcrumbs.push({
          id: '1003',
          title: 'Archive',
          to: 'projects-archive',
        });
      }

      breadcrumbs.push({
        id: '1004',
        title: this.address || '',
        to: { name: 'single-project', params: { id: this.projectId } },
      });
      breadcrumbs.push({
        id: '1005',
        title: 'Payment',
      });

      return breadcrumbs;
    },
    navMenu() {
      return [
        {
          id: 'single-project-nav-link-1001',
          attributes: {
            title: 'Contracts',
            pathName: 'single-project.contracts',
          },
        },
        {
          id: 'single-project-nav-link-1002',
          attributes: {
            title: 'Statements',
            pathName: 'single-project.statements',
          },
        },
        {
          id: 'single-project-nav-link-1003',
          attributes: {
            title: 'Internal Notes',
            pathName: 'single-project.internal-notes',
          },
        },
        {
          id: 'single-project-nav-link-1004',
          attributes: {
            title: 'Activity',
            pathName: 'single-project.activity',
          },
        },
      ];
    },
    projectBreakdown() {
      return [
        {
          id: 'single-project-project-breakdown-1001',
          attributes: {
            title: 'Landscaping',
            sum: this.singleProject?.attributes?.statistics?.landscaping?.sum || '0',
            qty: this.singleProject?.attributes?.statistics?.landscaping?.qty || '0',
            bg: 'bg-success',
          },
        },
        {
          id: 'single-project-project-breakdown-1002',
          attributes: {
            title: 'Pool',
            sum: this.singleProject?.attributes?.statistics?.pool?.sum || '0',
            qty: this.singleProject?.attributes?.statistics?.pool?.qty || '0',
            bg: 'bg-info',
          },
        },
        {
          id: 'single-project-project-breakdown-1003',
          attributes: {
            title: 'Woodwork',
            sum: this.singleProject?.attributes?.statistics?.woodwork?.sum || '0',
            qty: this.singleProject?.attributes?.statistics?.woodwork?.qty || '0',
            bg: 'bg-danger',
          },
        },
        {
          id: 'single-project-project-breakdown-1004',
          attributes: {
            title: 'Change Orders',
            sum: this.singleProject?.attributes?.statistics?.change_orders?.sum || '0',
            qty: this.singleProject?.attributes?.statistics?.change_orders?.qty || '0',
            bg: 'bg-warning',
          },
        },
      ];
    },
    about() {
      return [
        {
          id: 'single-project-about-1001',
          attributes: {
            title: `${this.singleProject?.attributes?.first_name || ''} ${
              this.singleProject?.attributes?.last_name || ''
            }`,
            icon: '/assets/media/icons/custom/user.svg',
          },
        },
        {
          id: 'single-project-about-1002',
          attributes: {
            title: this.singleProject?.attributes?.phone || '',
            icon: '/assets/media/icons/custom/tel.svg',
          },
        },
        {
          id: 'single-project-about-1003',
          attributes: {
            title: this.singleProject?.attributes?.email || '',
            icon: '/assets/media/icons/custom/mail.svg',
          },
        },
      ];
    },
    activityDates() {
      return {
        created: this.singleProject?.attributes?.created_at || '',
        updated: this.singleProject?.attributes?.updated_at || '',
      };
    },
  },
  data() {
    return {
      isMapLoading: false,
      isNoMapAvailable: false,
      center: {
        lat: 59,
        lng: -95,
      },
      isProjectModalOpened: false,
      isShareModalOpened: false,
    };
  },
  methods: {
    ...mapActions({
      actionInitSingleProject: 'singleProject/initProject',
    }),
    async init() {
      this.isMapLoading = true;

      await this.actionInitSingleProject({
        id: this.projectId,
        params: {
          fields:
            'status,balance,code,secret,archive,address,statistics,first_name,last_name,phone,email,created_at,updated_at,lat,lon,proposal_scopes,manager,city,province,postal,address_line_1,sharing',
        },
      });
      await this.setMapOption();

      this.isMapLoading = false;
    },
    setUserPosition(coords) {
      console.log(coords);
    },
    setMapOption() {
      if (!this.center.lat || !this.center.lng) {
        this.isNoMapAvailable = true;
      } else {
        this.center.lat = this.singleProject.attributes.lat;
        this.center.lng = this.singleProject.attributes.lon;
      }
    },
    openProjectModal() {
      this.isProjectModalOpened = true;
    },
    closeProjectModal() {
      this.isProjectModalOpened = false;
    },
    openShareModal() {
      this.isShareModalOpened = true;
    },
    closeShareModal() {
      this.isShareModalOpened = false;
    },

    async onProjectUpdate() {
      await this.init();
    },
  },
  async mounted() {
    await this.init();
  },
};
</script>
